import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  Info,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../core/redux/action";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { getBusiness, getProductType, updateProduct } from "../../service/api";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  price: yup.number().required("Price is required."),
  GSTPr: yup.number().required("GST percentage is required."),
  desc: yup.string().required("Description is required."),
  businessId: yup.string().required("Business is required."),
  typeNumberId: yup.string().required("Product Type is required."),
});
const EditProduct = () => {
  const route = all_routes;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();

  const [businessData, setBusinessData] = useState([]);
  const [productType, setProductType] = useState([]);
  const data = useSelector((state) => state.toggle_header);
  const { singleProduct } = useSelector((state) => state);
  console.log("%c Line:35 🍔 singleProduct", "color:#ffdd4d", singleProduct);

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  let init = {
    name: "",
    price: "",
    desc: "",
    GSTPr: "",
    businessId: "",
    typeNumberId: "",
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      updateProduct(id, values)
        .then((res) => {
          console.log("res--->", res);
          if (res?.status) {
            toast.success(res?.message);
            formik.resetForm();
            navigate(route.productlist);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (singleProduct) {
      formik.setFieldValue("name", singleProduct?.name);
      formik.setFieldValue("price", singleProduct?.price);
      formik.setFieldValue("desc", singleProduct?.desc);
      formik.setFieldValue("GSTPr", singleProduct?.GSTPr);
      formik.setFieldValue("businessId", singleProduct?.business);
      formik.setFieldValue("typeNumberId", singleProduct?.productTypeNumber);
      setId(singleProduct?._id);
    }
  }, [singleProduct]);

  const getData = () => {
    getBusiness()
      .then((res) => {
        // console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          if (res?.data?.length > 0) {
            setBusinessData(res?.data);
            // formik.setFieldValue("businessId", res?.data[0]?._id);
          } else {
            setBusinessData([]);
          }
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  const getDataProduct = () => {
    getProductType({ page: 1, sizePerPage: 1000 })
      .then((res) => {
        // console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          setProductType(res?.data?.docs);
        } else {
          setProductType([]);
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };
  useEffect(() => {
    getData();
    getDataProduct();
  }, []);
  console.log("%c Line:139 🥝 formik", "color:#b03734", formik);
  return (
    <div className="page-wrapper">
      <form onSubmit={formik.handleSubmit}>
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Edit Product</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <div className="page-btn">
                  <Link to={route.productlist} className="btn btn-secondary">
                    <ArrowLeft className="me-2" />
                    Back to Product
                  </Link>
                </div>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Collapse"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp className="feather-chevron-up" />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Product Information</span>
                        </h5>
                        <Link to="#">
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Product Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={
                                formik.errors.name && formik.touched.name
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              name="name"
                              id="name"
                              value={formik?.values?.name}
                              onChange={(e) => formik.handleChange(e)}
                            />
                            <div className="invalid-feedback">
                              {formik.errors.name && formik.touched.name
                                ? formik.errors.name
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Product Price</label>
                            <input
                              type="number"
                              className={
                                formik.errors.price && formik.touched.price
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              name="price"
                              id="price"
                              value={formik?.values?.price}
                              onChange={(e) => formik.handleChange(e)}
                            />
                            <div className="invalid-feedback">
                              {formik.errors.price && formik.touched.price
                                ? formik.errors.price
                                : ""}
                            </div>
                          </div>{" "}
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              GST Percentage
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className={
                                formik.errors.GSTPr && formik.touched.GSTPr
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              name="GSTPr"
                              id="GSTPr"
                              value={formik?.values?.GSTPr}
                              onChange={(e) => formik.handleChange(e)}
                            />
                            <div className="invalid-feedback">
                              {formik.errors.GSTPr && formik.touched.GSTPr
                                ? formik.errors.GSTPr
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 cadd-product">
                            <label className="form-label">
                              Business<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className={
                                formik.errors.businessId &&
                                formik.touched.businessId
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              aria-label="Default select example"
                              name="businessId"
                              id="businessId"
                              value={formik?.values?.businessId}
                              onChange={(e) => formik.handleChange(e)}
                            >
                              <option value="" disabled>
                                Select Business
                              </option>
                              {businessData && businessData?.length > 0 ? (
                                businessData?.map((obj, i) => {
                                  return (
                                    <option key={i} value={obj?._id}>
                                      {obj?.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>
                                  Please add your business details.
                                </option>
                              )}
                            </select>

                            <div className="invalid-feedback">
                              {formik.errors.businessId &&
                              formik.touched.businessId
                                ? formik.errors.businessId
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 cadd-product">
                            <label className="form-label">
                              Product Type
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className={
                                formik.errors.typeNumberId &&
                                formik.touched.typeNumberId
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              aria-label="Default select example"
                              name="typeNumberId"
                              id="typeNumberId"
                              value={formik?.values?.typeNumberId}
                              onChange={(e) => formik.handleChange(e)}
                            >
                              <option value="" disabled>
                                Select Product Type
                              </option>
                              {productType && productType?.length > 0 ? (
                                productType?.map((obj, i) => {
                                  return (
                                    <option key={i} value={obj?._id}>
                                      {obj?.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>
                                  Please add your product type details.
                                </option>
                              )}
                            </select>

                            <div className="invalid-feedback">
                              {formik.errors.typeNumberId &&
                              formik.touched.typeNumberId
                                ? formik.errors.typeNumberId
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            className={` h-100 ${
                              formik.errors.desc && formik.touched.desc
                                ? " form-control is-invalid"
                                : "form-control"
                            }`}
                            rows={5}
                            value={formik?.values?.desc}
                            onChange={(e) => formik.handleChange(e)}
                            name="desc"
                            id="desc"
                          />
                          <p className="mt-1">Maximum 60 Characters</p>
                          <div className="invalid-feedback">
                            {formik.errors.desc && formik.touched.desc
                              ? formik.errors.desc
                              : ""}
                          </div>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4 d-flex">
              <button
                type="button"
                style={{ width: "100px" }}
                className="btn btn-cancel me-2"
              >
                Cancel
              </button>
              <button
                // onClick={handleData}
                type="submit"
                disabled={loading}
                className="btn btn-submit me-2 d-flex align-items-center justify-content-center"
                style={{ width: "130px" }}
              >
                {loading ? (
                  <Spinner
                    style={{ height: "20px", width: "20px" }}
                    className="me-2"
                  />
                ) : (
                  "Save Product"
                )}
              </button>
            </div>
          </div>
          {/* /add */}
        </div>
      </form>
    </div>
  );
};

export default EditProduct;
