/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { Spinner } from "react-bootstrap";
import { addCustomer, getBusiness } from "../../../service/api";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  desc: yup.string().required("Email is required"),
  addressLine1: yup.string().required("Address is required."),
  addressLine2: yup.string().required("Street is required."),
  gstNumber: yup
    .string()
    .matches(
      /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([1-9A-Z]{1})(Z)([0-9A-Z]{1})$/,
      "Invalid GST Number format."
    )
    .required("GST Number is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  pinCode: yup.string().required("PIN Code is required."),
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile is required."),
  accountNumber: yup.number().required("Account Number is required."),
  bankName: yup.string().required("Bank Name is required."),
  holderName: yup.string().required("Account Name is required."),
  ifscCode: yup.string().required("IFSC is required."),
  // businessId: yup.string().required("Business is required."),
});

const AddCustomer2 = ({ show, getData, setShow }) => {
  const [business, setBusiness] = useState();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      mobile: "",
      gstNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      holderName: "",
      // businessId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("name", values?.name);
      formdata.append("desc", values?.desc);
      formdata.append("mobile", values?.mobile);
      formdata.append("gstNumber", values?.gstNumber);
      let address = {
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2,
        state: values?.state,
        city: values?.city,
        pinCode: values?.pinCode.toString(),
      };
      formdata.append("bankName", values?.bankName);
      formdata.append("accountNumber", values?.accountNumber);
      formdata.append("ifscCode", values?.ifscCode);
      formdata.append("holderName", values?.holderName);
      formdata.append("businessId", business && business[0]?._id);

      formdata.append("address", JSON.stringify(address));
      addCustomer(formdata)
        .then((res) => {
          console.log("res--->", res);
          if (res?.status) {
            toast.success(res?.message);
            getData();
            formik.resetForm();
            setShow(false);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (!business) {
      getBusiness()
        .then((res) => {
          console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
          if (res?.status) {
            setBusiness(res?.data);
          }
        })
        .catch((e) => {
          console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
        });
    }
  }, [business]);
  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      id="add-customer"
      tabIndex="-1"
      role="dialog"
      aria-hidden={!show}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered custom-modal-two">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <form className="form-details" onSubmit={formik.handleSubmit}>
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Customer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShow(false)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div
                  className="modal-body custom-modal-body"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                  }}
                >
                  {/* <div className="modal-title-head people-cust-avatar">
                      <h6>Avatar</h6>
                    </div>
                    <div className="new-employee-field">
                      <div className="profile-pic-upload">
                        <div className="profile-pic">
                          <span>
                            <i
                              data-feather="plus-circle"
                              className="plus-down-add"
                            />{" "}
                            Add Image
                          </span>
                        </div>
                        <div className="mb-3">
                          <div className="image-upload mb-0">
                            <input type="file" />
                            <div className="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  <div className="form-row row">
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Customer Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name &&
                            formik.touched.name &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.name && formik.touched.name
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="name"
                          id="name"
                          value={formik?.values?.name}
                          onChange={(e) => formik.handleChange(e)}
                        />{" "}
                        <div className="invalid-feedback">
                          {formik.errors.name && formik.touched.name
                            ? formik.errors.name
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className={`form-control ${
                            formik.errors.desc &&
                            formik.touched.desc &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.desc && formik.touched.desc
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="desc"
                          id="desc"
                          value={formik?.values?.desc}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.desc && formik.touched.desc
                            ? formik.errors.desc
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="input-blocks">
                        <label className="mb-2">
                          Mobile<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            formik.errors.mobile &&
                            formik.touched.mobile &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.mobile && formik.touched.mobile
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="mobile"
                          id="mobile"
                          value={formik?.values?.mobile}
                          onChange={(e) => formik.handleChange(e)}
                        />{" "}
                        <div className="invalid-feedback">
                          {formik.errors.mobile && formik.touched.mobile
                            ? formik.errors.mobile
                            : ""}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          GST Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.gstNumber &&
                            formik.touched.gstNumber &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.gstNumber && formik.touched.gstNumber
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="gstNumber"
                          id="gstNumber"
                          value={formik?.values?.gstNumber}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.gstNumber && formik.touched.gstNumber
                            ? formik.errors.gstNumber
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 pe-0">
                      <div className="mb-3">
                        <label className="form-label">
                          Address<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.addressLine1 &&
                            formik.touched.addressLine1 &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.addressLine1 &&
                            formik.touched.addressLine1
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="addressLine1"
                          id="addressLine1"
                          value={formik?.values?.addressLine1}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.addressLine1 &&
                          formik.touched.addressLine1
                            ? formik.errors.addressLine1
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Street<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.addressLine2 &&
                            formik.touched.addressLine2 &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.addressLine2 &&
                            formik.touched.addressLine2
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="addressLine2"
                          id="addressLine2"
                          value={formik?.values?.addressLine2}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.addressLine2 &&
                          formik.touched.addressLine2
                            ? formik.errors.addressLine2
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          City<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.city &&
                            formik.touched.city &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.city && formik.touched.city
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="city"
                          id="city"
                          value={formik?.values?.city}
                          onChange={(e) => formik.handleChange(e)}
                        />{" "}
                        <div className="invalid-feedback">
                          {formik.errors.city && formik.touched.city
                            ? formik.errors.city
                            : ""}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          State<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.state &&
                            formik.touched.state &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.state && formik.touched.state
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="state"
                          id="state"
                          value={formik?.values?.state}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.state && formik.touched.state
                            ? formik.errors.state
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Pin Code<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            formik.errors.pinCode &&
                            formik.touched.pinCode &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.pinCode && formik.touched.pinCode
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="pinCode"
                          id="pinCode"
                          value={formik?.values?.pinCode}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.pinCode && formik.touched.pinCode
                            ? formik.errors.pinCode
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Bank Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.bankName &&
                            formik.touched.bankName &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.bankName && formik.touched.bankName
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="bankName"
                          id="bankName"
                          value={formik?.values?.bankName}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formik.errors.bankName && formik.touched.bankName
                            ? formik.errors.bankName
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          IFSC Code<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.ifscCode &&
                            formik.touched.ifscCode &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.ifscCode && formik.touched.ifscCode
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="ifscCode"
                          id="ifscCode"
                          value={formik?.values?.ifscCode}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.ifscCode && formik.touched.ifscCode
                            ? formik.errors.ifscCode
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Account Number
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control ${
                            formik.errors.accountNumber &&
                            formik.touched.accountNumber &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.accountNumber &&
                            formik.touched.accountNumber
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="accountNumber"
                          id="accountNumber"
                          value={formik?.values?.accountNumber}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.accountNumber &&
                          formik.touched.accountNumber
                            ? formik.errors.accountNumber
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Account holder name
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.holderName &&
                            formik.touched.holderName &&
                            "is-invalid"
                          }`}
                          style={
                            formik.errors.holderName &&
                            formik.touched.holderName
                              ? { borderColor: "#dc3545" }
                              : { borderColor: "#e9edf6" }
                          }
                          name="holderName"
                          id="holderName"
                          value={formik?.values?.holderName}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.holderName && formik.touched.holderName
                            ? formik.errors.holderName
                            : ""}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          />
                          <p>Maximum 60 Characters</p>
                        </div>
                      </div> */}
                  </div>
                  <div className="modal-footer-btn d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      style={{ width: "100px" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-submit me-2 d-flex align-items-center justify-content-center"
                      style={{ width: "100px" }}
                    >
                      {loading ? (
                        <Spinner
                          style={{ height: "20px", width: "20px" }}
                          className="me-2"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomer2;
