/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  addProductType,
  getBusiness,
  updateProductType,
} from "../../../service/api";
import { Spinner } from "react-bootstrap";
// import { Button } from "react-bootstrap";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  number: yup.number().required("Number is required."),
  desc: yup.string().required("Description is required."),
  businessId: yup.string().required("Business is required."),
  unit: yup.string().required("Unit is required."),
  type: yup.string().required("Type is required."),
});
const AddProductTypeList = ({ edit, selectedData, getListData }) => {
  const [loading, setLoading] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  let init = {
    name: "",
    type: "",
    number: "",
    desc: "",
    businessId: "",
    unit: "",
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (edit) {
        updateProductType(selectedData?._id, values)
          .then((res) => {
            console.log("res--->", res);
            if (res?.status) {
              getListData();
              toast.success(res?.message);
              formik.resetForm();
              document
                ?.getElementById("add-category")
                ?.classList?.remove("show", "d-block", "modal-open");
              document
                ?.getElementsByClassName("modal-backdrop")[0]
                ?.classList?.remove("modal-backdrop");
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addProductType(values)
          .then((res) => {
            console.log("res--->", res);
            if (res?.status) {
              toast.success("Product Type Added Successfully");
              formik.resetForm();
              getListData();
              document
                ?.getElementById("add-category")
                ?.classList?.remove("show", "d-block", "modal-open");
              document
                ?.getElementsByClassName("modal-backdrop")[0]
                ?.classList?.remove("modal-backdrop");
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  useEffect(() => {
    if (edit) {
      if (selectedData?.name) {
        formik.setFieldValue("name", selectedData?.name);
      }
      if (selectedData?.desc) {
        formik.setFieldValue("desc", selectedData?.desc);
      }
      if (selectedData?.type) {
        formik.setFieldValue("type", selectedData?.type);
      }
      if (selectedData?.unit) {
        formik.setFieldValue("unit", selectedData?.unit);
      }
      if (selectedData?.number) {
        formik.setFieldValue("number", selectedData?.number);
      }
      if (selectedData?.business) {
        formik.setFieldValue("businessId", selectedData?.business);
      }
    } else {
      formik.resetForm();
    }
  }, [edit, selectedData]);
  const getData = () => {
    getBusiness()
      .then((res) => {
        console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          if (res?.data?.length > 0) {
            setBusinessData(res?.data);
            // formik.setFieldValue("businessId", res?.data[0]?._id);
          } else {
            setBusinessData([]);
          }
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  console.log("formik: ", formik);

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-lg modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <form className="form-details" onSubmit={formik.handleSubmit}>
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>{edit ? "Edit" : "Create"} Product Type</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div
                    className="modal-body custom-modal-body"
                    // style={{
                    //   height: "60vh",
                    //   overflowY: "scroll",
                    //   scrollbarWidth: "none",
                    // }}
                  >
                    <div className="form-row row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label">
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.errors.name && formik.touched.name
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                          name="name"
                          id="name"
                          value={formik?.values?.name}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.name && formik.touched.name
                            ? formik.errors.name
                            : ""}
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">
                          Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className={
                            formik.errors.number && formik.touched.number
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                          name="number"
                          id="number"
                          value={formik?.values?.number}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.number && formik.touched.number
                            ? formik.errors.number
                            : ""}
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">
                          Business<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            formik.errors.businessId &&
                            formik.touched.businessId
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                          aria-label="Default select example"
                          name="businessId"
                          id="businessId"
                          value={formik?.values?.businessId}
                          onChange={(e) => formik.handleChange(e)}
                        >
                          <option value="" disabled>
                            Select Business
                          </option>
                          {businessData && businessData?.length > 0 ? (
                            businessData?.map((obj, i) => {
                              return (
                                <option key={i} value={obj?._id}>
                                  {obj?.name}
                                </option>
                              );
                            })
                          ) : (
                            <option disabled>
                              Please add your business details.
                            </option>
                          )}
                        </select>

                        <div className="invalid-feedback">
                          {formik.errors.businessId && formik.touched.businessId
                            ? formik.errors.businessId
                            : ""}
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">
                          Unit<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.errors.unit && formik.touched.unit
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                          name="unit"
                          id="unit"
                          value={formik?.values?.unit}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.unit && formik.touched.unit
                            ? formik.errors.unit
                            : ""}
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">
                          Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            formik.errors.type && formik.touched.type
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                          name="type"
                          id="type"
                          value={formik?.values?.type}
                          onChange={(e) => formik.handleChange(e)}
                        >
                          <option value="" disabled>
                            Select Type
                          </option>
                          <option value={"GOODS"}>GOODS</option>
                          <option value={"SERVICES"}>SERVICES</option>
                        </select>
                        <div className="invalid-feedback">
                          {formik.errors.type && formik.touched.type
                            ? formik.errors.type
                            : ""}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">
                          Description<span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          rows={5}
                          cols={5}
                          className={
                            formik.errors.desc && formik.touched.desc
                              ? " form-control is-invalid"
                              : "form-control"
                          }
                          name="desc"
                          id="sign"
                          value={formik?.values?.desc}
                          spellCheck={true}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.desc && formik.touched.desc
                            ? formik.errors.desc
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        style={{ width: "100px" }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loading}
                        className="btn btn-submit"
                        type="submit"
                        style={{ width: "100px" }}
                      >
                        {loading ? (
                          <Spinner
                            style={{ height: "18px", width: "18px" }}
                            className="me-2"
                          />
                        ) : edit ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddProductTypeList;
